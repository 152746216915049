import React from "react";
import { Link } from "react-router-dom";
import Noodle from "../../../assets/Noodle-landing.png";
import LandingPaw from "../../../assets/landing-paw.svg";

const Landing = () => {
  return (
    <>
      <div className="left">
        <h1 className="hideMobile">
          HAPPY PUP,
          <br />
          HAPPY FUR-MILY
        </h1>
        <h1 className="hideDesktop mobile-heading">
          HAPPY PUP,
          <span className="new-line">HAPPY</span>
          <span className="no-wrap">FUR-MILY</span>
        </h1>
        <div className="landing-subtitle">
          <p>Your local Bayside & Kingston dog sitters</p>
          <p>
            Let your fur babies live their best life while you live yours. Book
            a stay with us today!
          </p>
        </div>
        <div className="buttons">
          <Link to="/book" className="book-now-btn">
            Book now
          </Link>
        </div>
      </div>
      <div className="landing-paw hideDesktop">
        <img alt="" src={LandingPaw} className={"landing-paw-img"} />
      </div>
      <div className="right">
        <img alt="dog" src={Noodle} className="noodle hideMobile" />
      </div>
    </>
  );
};

export default Landing;
