import React from "react";
import { Helmet } from "react-helmet-async";
import { SITE_NAME } from "../../constants";
import Landing from "./sections/Landing";
import Services from "./sections/Services";
import Booking from "./sections/Booking";
import About from "./sections/About";
import Testimonials from "./Testimonials/Testimonials";
import Referfriend from "./sections/Referfriend";
import "./Home.css";

const Home = () => {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{SITE_NAME}</title>
        <meta
          name="description"
          content="Home page of MyWooflySitters. MyWooflySitters are dog sitters who are based in Cheltenham, Vitoria, 3192. They provide services for dog sitting, boarding and dog walking"
        />
      </Helmet>
      <section id="landing" className="flex-center">
        <div className="landing-content-block mob-column-reverse landing">
          <Landing />
        </div>
      </section>

      <section className="flex-center">
        <div className="landing-content-block mob-column-reverse">
          <Services />
        </div>
      </section>

      <section className="flex-center">
        <div className="landing-content-block">
          <About />
        </div>
      </section>

      <section className="flex-center">
        <div className="landing-content-block mob-column-reverse">
          <Booking />
        </div>
      </section>

      <section className="flex-center">
        <div className="landing-content-testimonials">
          <Testimonials />
        </div>
      </section>

      <section>
        <div className="landing-content-refer">
          <Referfriend />
        </div>
      </section>
    </>
  );
};

export default Home;
