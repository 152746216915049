import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./Header.css";
import logo from "../../assets/MyWooflySitters-logo.svg";
import MenuIcon from "../../assets/MenuIcon.svg";
import CrossIcon from "../../assets/Cross.svg";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

const NavLinks = ({ toggleMenu }) => {
  return (
    <>
      <li>
        <NavLink
          activeClassName="active"
          className="link"
          to="/services"
          onClick={toggleMenu}
        >
          Services
        </NavLink>
      </li>
      <li>
        <NavLink
          activeClassName="active"
          className="link"
          to="/contact"
          onClick={toggleMenu}
        >
          Contact
        </NavLink>
      </li>
      <li className="bookNowLi">
        <NavLink to="/book" className="book-now-btn" onClick={toggleMenu}>
          Book now
        </NavLink>
      </li>
    </>
  );
};

const FullScreenMenu = ({ toggleMenu }) => {
  return (
    <aside className={`fullScreen`}>
      <div className="mobileNavLinks">
        <NavLinks toggleMenu={toggleMenu} />
      </div>
    </aside>
  );
};

const MenuLink = ({ toggleMenu, isMenuOpen }) => {
  return (
    <>
      <button className="menuButton unstyledButton" onClick={toggleMenu}>
        <img src={isMenuOpen ? CrossIcon : MenuIcon} alt="menu" />
      </button>
    </>
  );
};

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    if (!isMenuOpen) {
      document.documentElement.classList.add("preventScroll");
      document.body.classList.add("preventScroll");
    } else {
      document.documentElement.classList.remove("preventScroll");
      document.body.classList.remove("preventScroll");
    }
  };

  useEffect(() => {
    return () => {
      document.documentElement.classList.remove("preventScroll");
      document.body.classList.remove("preventScroll");
    };
  }, []);

  return (
    <header className="header">
      <nav className="nav">
        <Link title="Home page" to="/" onClick={isMenuOpen && toggleMenu}>
          <div className="logo">
            <img src={logo} alt="" />
          </div>
        </Link>
        <ul className="desktopNav desktopNavUl">
          <NavLinks />
        </ul>
        <MenuLink toggleMenu={toggleMenu} isMenuOpen={isMenuOpen} />
      </nav>
      {isMenuOpen && <FullScreenMenu toggleMenu={toggleMenu} />}
    </header>
  );
};

export default Header;
