import React from "react";
import { Link } from "react-router-dom";
import Noodle from "../../../assets/WoofServices.svg";
import WoofServiceTile from "../../../assets/WoofServices.png";

const Services = () => {
  return (
    <>
      {" "}
      <div className="content-width-left">
        <h2>Woof Services</h2>
        <div className="right">
          <img className="hideDesktop" src={WoofServiceTile} alt="" />
        </div>
        <p>
          Looking for a dog sitter? MyWooflySitters are a reliable couple that
          duo together to help you with your pup(s) when you book your next trip
          away! We offer dog boarding and day care at our home in Cheltenham and
          walks and house visits in all surrounding suburbs.{" "}
        </p>
        <div className="buttons">
          <Link to="/book" className="book-now-btn">
            Book now
          </Link>
        </div>
      </div>
      <div className="right">
        <img className="hideMobile" src={Noodle} alt="" />
      </div>
    </>
  );
};

export default Services;
