import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/MyWooflySitters-logo.svg";
import email from "../assets/email.png";
import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";

const Footer = () => {
  return (
    <footer>
      <div className="footer-container">
        <div className="footer-left">
          <Link to="/">
            <div className="logo">
              <img src={logo} alt="{Logo}" />
            </div>
          </Link>
          <p>&copy; 2024 MyWooflySitters. All Rights Reserved</p>
        </div>
        <div className="footer-links">
          <div className="footer-email">
            <a href="mailto:mywooflysitters@gmail.com">
              <img src={email} alt="{Email}" />
            </a>
          </div>
          <div className="footer-facebook">
            <img src={facebook} alt="{Facebook}" />
          </div>
          <div className="footer-instagram">
          <a href="https://www.instagram.com/mywooflysitters/">
            <img src={instagram} alt="{Instagram}" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
