import { useForm, ValidationError } from "@formspree/react";
import { useState } from "react";
import "./ContactForm.css";

export default function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    service: "",
    message: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [state, handleSubmit] = useForm("myzggllq");

  if (state.succeeded) {
    return <p>Thanks for your submission!</p>;
  }

  return (
    <form method="POST" onSubmit={handleSubmit}>
      <label htmlFor="name">Full Name</label>
      <input id="name" type="text" name="name" required />
      <ValidationError prefix="Name" field="name" errors={state.errors} />

      <label htmlFor="email">Email Address</label>
      <input id="email" type="email" name="email" required />
      <ValidationError prefix="Email" field="email" errors={state.errors} />

      <label htmlFor="mobile">Mobile Number</label>
      <input id="mobile" type="text" name="mobile" required />
      <ValidationError prefix="Email" field="mobile" errors={state.errors} />

      <label htmlFor="referer">Referer Name</label>
      <input id="referer" type="text" name="referer" />
      <ValidationError prefix="Referer" field="referer" errors={state.errors} />

      <div>
        <label htmlFor="service">Preferred Service:</label>
        <select
          id="service"
          name="service"
          value={formData.service}
          onChange={handleInputChange}
          required
        >
          <option value="">Select a service</option>
          <option value="Daily Dog Walking">Daily Dog Walking</option>
          <option value="Overnight Boarding">Overnight Boarding</option>
          <option value="Pet Sitting at Home">Pet Sitting at Home</option>
          <option value="Customized Care Plan">Customized Care Plan</option>
        </select>
        <ValidationError prefix="Email" field="email" errors={state.errors} />
      </div>

      <label htmlFor="message">Message</label>
      <textarea id="message" name="message" required></textarea>
      <ValidationError prefix="Message" field="message" errors={state.errors} />

      <button
        className={"submitButton"}
        type="submit"
        disabled={state.submitting}
      >
        Submit
      </button>
      <ValidationError errors={state.errors} />
    </form>
  );
}
