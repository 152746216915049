import React from "react";
import { Helmet } from "react-helmet-async";
import { SITE_NAME } from "../constants";
import ContactForm from "./ContactForm/ContactForm";

const Contact = () => {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{SITE_NAME} - Contact Us</title>
        <meta
          name="description"
          content="Contact page of MyWooflySitters. Allowing customers to contact the MyWooflySitters team."
        />
      </Helmet>
      <div className="flex-center pageBackground">
        <div className="page-content-block">
          <div className="content-width-left">
            <h2>Contact</h2>
            <p>
              Can't find what you're looking for? Send us an inquiry or give us
              a call! MyWooflySitters is here to discuss your needs and ensure
              your pup gets the best care.{" "}
            </p>
          </div>
          <div className="flex-contact">
            <div className="box-1">
              <p>
                <b>Find us on:</b>
              </p>

              <div className="finduson">
                <a href="https://www.madpaws.com.au/petsitter/cheltenham-vic/jen-t-2-wfh-dog-lovers">
                  Madpaws
                </a>
                <span> | </span>
                <a href="https://www.pawshake.com.au/sitter/APAC_WSxL9dmHoJg">
                  Pawshake
                </a>
              </div>
            </div>
            <div className="box-2">
              <p>
                <b>Email</b>{" "}
              </p>
              <a href="mailto:mywooflysitters@gmail.com">
                mywooflysitters@gmail.com
              </a>
            </div>
            <div className="box-3">
              <p>
                <b>Mobile</b>{" "}
              </p>
              <a href="tel:+61412031170">0412031170</a>
            </div>
          </div>

          <ContactForm />
        </div>
      </div>
    </>
  );
};

export default Contact;
