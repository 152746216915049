import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import ServicePrice from "./components/ServicePrice";
import Contact from "./components/Contact";
import Footer from "./components/Footer";
import "./App.css";
import BookNow from "./components/BookNow";

import { useLocation } from "react-router-dom";

//Scrolls to top whenever the pathname updates
export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
  }, [pathname]);

  return null;
};

function App() {
  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <div className="App">
          <Header />
          <main>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route path="/services" element={<ServicePrice />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/book" element={<BookNow />} />
            </Routes>
          </main>

          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
}

export default App;
