import React from "react";

const Referfriend = () => {
  return (
    <>
      <div class="content-width-left">
        <h2>GET 10% OFF TODAY</h2>
        <p>Refer a pawfriend and get 10% off your next booking!</p>
      </div>
    </>
  );
};

export default Referfriend;
