import React from "react";
import TestimonialBlock from "./TestimonialBlock";
import MaxPic from "../../../assets/max.png";
import LunaPic from "../../../assets/Luna.png";
import LennyWalter from "../../../assets/LennyWalter.png";
import "./Testimonials.css";

const Testimonials = () => {
  return (
    <>
      <div className="content-width-left maxWidth testimonialContentBlock">
        <h2>TESTIMONIALS</h2>
        <p className="testimonialContent"></p>
      </div>
      <div className="testimonial-section">
        <div className="testimonial-block">
          <TestimonialBlock
            star={5}
            size={100}
            dog={{
              name: "Max",
              date: "06/04/2024",
              imageId: MaxPic,
              description:
                "Jen and Ant were so caring. They kept us updated and really embraced Max as their own. It was such a relief knowing he was happy and well looked after while we were away.",
            }}
          />
        </div>

        <div className="testimonial-block">
          <TestimonialBlock
            star={5}
            size={100}
            dog={{
              name: "LUNA (PUPPY)",
              date: "26/12/2023",
              imageId: LunaPic,
              description:
                "Jen and Antony are an awesome duo, super friendly, responsible and willing to accommodate your requests/concerns. Would not hesitate to leave my dog with them again.",
            }}
          />
        </div>

        <div className="testimonial-block">
          <TestimonialBlock
            star={5}
            size={100}
            dog={{
              name: "LENNY & WALTER",
              date: "27/11/2023",
              imageId: LennyWalter,
              description:
                "Jen and Ant are beyond fantastic with our Lenny and Walter! So caring of their little quirks and needs. Always great communicators and we love their photos. We always appreciate their care.",
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Testimonials;
