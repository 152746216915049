import React from "react";
import { Helmet } from "react-helmet-async";
import ContactForm from "./ContactForm/ContactForm";
import { SITE_NAME } from "../constants";

const BookNow = () => {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{SITE_NAME} - Book Now</title>
        <meta
          name="description"
          content="Book now page of MyWooflySitters. Allowing customers to book any of the dog sitting services offered."
        />
      </Helmet>
      <div className="flex-center pageBackground">
        <div className="page-content-block">
          <div className="content-width-left">
            <h2>Book with us</h2>
            <p>
              Let us know which dates you are interested in as well as the
              service in the form below, and we will get back to you within 24
              hours.
            </p>
          </div>
          <div className="flex-contact">
            <div className="box-1">
              <p>
                <b>Find us on:</b>
              </p>

              <div className="finduson">
                <a href="https://www.madpaws.com.au/petsitter/cheltenham-vic/jen-t-2-wfh-dog-lovers">
                  Madpaws
                </a>
                <span> | </span>
                <a href="https://www.pawshake.com.au/sitter/APAC_WSxL9dmHoJg">
                  Pawshake
                </a>
              </div>
            </div>
            <div className="box-2">
              <p>
                <b>Email</b>{" "}
              </p>
              <a href="mailto:mywooflysitters@gmail.com">
                mywooflysitters@gmail.com
              </a>
            </div>
            <div className="box-3">
              <p>
                <b>Mobile</b>{" "}
              </p>
              <a href="tel:+61412031170">0412031170</a>
            </div>
          </div>

          <ContactForm />
        </div>
      </div>
    </>
  );
};

export default BookNow;
