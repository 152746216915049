import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { SITE_NAME } from "../constants";

const ServicePrice = () => {
  return (
    <>
      <Helmet prioritizeSeoTags>
        <title>{SITE_NAME} - Services</title>
        <meta
          name="description"
          content="Service page of MyWooflySitters. Allowing customers to view the prices and services offered by MyWooflySitters."
        />
      </Helmet>
      <div className="flex-center pageBackground">
        <div className="page-content-block">
          <div className="content-width-left">
            <h2>OUR SERVICES</h2>
            <p>
              We offer dog boarding and day care in our home in Cheltenham and
              walks and house visits in all surrounding suburbs.
            </p>
          </div>
          <div className="flex">
            <div className="box-1">
              <h3>DOG BOARDING</h3>
              <h4 className="service-title">Overnight stay at our home</h4>
              <p>Rate per night - $70</p>
            </div>
            <div className="box-2">
              <h3>HOUSE VISITS</h3>
              <h4 className="service-title">Play and feed</h4>
              <p>1x visit - $25</p>
              <p>2x visit - $40</p>
            </div>
            <div className="box-3">
              <h3>DAY CARE</h3>
              <h4 className="service-title">Day care at our home</h4>
              <p>Rate per day - $55</p>
            </div>
            <div className="box-4">
              <h3>DOG WALKING</h3>
              <h4 className="service-title">Walk or spend time with dog</h4>
              <p>30 min walk - $35</p>
              <p>60 min walk - $55</p>
            </div>
          </div>

          <div className="content-width-right">
            <p>
              ** Additional Pet Surcharge - $10 on top of any service booked per
              day/walk
            </p>
          </div>

          <div className="buttons">
            <Link to="/book" className="book-now-btn">
              Book now
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ServicePrice;
