import React from "react";
import { Link } from "react-router-dom";
import JenAnt from "../../../assets/Jen&Ant.png";
import Pawpaw from "../../../assets/Pawpaw.svg";

const About = () => {
  return (
    <>
      <div className="right">
        <img className="paddingAbout hideMobile" src={JenAnt} alt="" />
      </div>

      <div className="content-width-left">
        <h2>ABOUT THE WOOF FAM</h2>
        <div className="right">
          <img className="paddingAbout hideDesktop" src={JenAnt} alt="" />
        </div>
        <p className="hideMobile">
          Founded by Ant and Jen in December 2022, TheWooflySitters offer a
          variety of dog services, including walking and boarding in their
          Cheltenham, Victoria home. As an active couple working from home, they
          provide your pup with plenty of attention and enjoy taking them on
          lunch and evening walks. With over a year of professional dog care
          experience, Ant and Jen started their journey out of their love for
          pups and traveling.
        </p>
        <p className="hideDesktop">
          Founded by Ant and Jen in December 2022, TheWooflySitters offers a
          variety of dog services, including walking and boarding in their
          Cheltenham, Victoria home. With over a year of professional dog care
          experience, Ant and Jen started their journey out of their love for
          pups and traveling.
        </p>
        <div className="buttons">
          <Link to="/book" className="book-now-btn">
            Book now
          </Link>
        </div>
        <div className="pawpaw">
          <img src={Pawpaw} alt="" />
        </div>
      </div>
    </>
  );
};

export default About;
