import React from "react";
import { Link } from "react-router-dom";
import BookingSteps from "../../../assets/BookingSteps.png";

const Booking = () => {
  return (
    <>
      <div className="content-width-left">
        <h2>MAKE A BOOKING TODAY</h2>
        <p className="hideMobile">
          We want to make sure you and your pup(s) are comfortable with our
          services, so reach out for an initial consultation to discuss any
          concerns or arrangements. Once you’re all happy with everything, we
          will proceed with a meet and greet where you and your pup(s) get to
          meet us and visit our home.
        </p>
        <p className="hideDesktop">
          We want to make sure you and your pup(s) are comfortable with our
          services, so reach out for an initial consultation to discuss any
          concerns or arrangements.
        </p>
        <div className="right bookingImageMobile">
          <img className="hideDesktop" src={BookingSteps} alt="" />
        </div>
        <div className="buttons">
          <Link to="/book" className="book-now-btn">
            Book now
          </Link>
        </div>
      </div>
      <div className="right">
        <img className="hideMobile" src={BookingSteps} alt="" />
      </div>
    </>
  );
};

export default Booking;
