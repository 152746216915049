import React from "react";
//import stars from "../assets/single-star.png"

const TestimonialBlock = ({ dog, size, star }) => {
  //const displayStarRatings = (star) => {
  //    let starRating=[];
  //    for (let i = 0; i < star; i++) {
  //         starRating[i]=<img src={stars} alt="{Logo}" width="30" height="28"/>
  //    }
  //    return starRating;
  //}

  return (
    <>
      <div className="testimonial-width-left">
        <div className="testimonial-dog-pic">
          <img src={dog.imageId} alt="" width={size} height={size} />
        </div>
        <div className="testimonial-dog-info">
          <h3>{dog.name}</h3>
          <p>{dog.date}</p>
        </div>
      </div>
      <div className="right">
        <div className="testimonial-dog-description">
          <p>"{dog.description}"</p>
        </div>
      </div>
    </>
  );
};

export default TestimonialBlock;
